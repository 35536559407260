<template>
    <div class="float">
        <div v-show="$globalData.data.status == '2'">
            <br />
            <br />
            <div style="position: absolute; ">
                <img :src="`https://newpsb.baiturrahman.com/php.script/${this.$globalData.data.pasfoto}`"
                    style="margin-top: 60px; margin-left: 55px; width: 85px; height: 110px;" />
                <div style="width: 100%;">
                    <div class="mt-2 text-center" style="font-size: 13px;">{{ $globalData.data.nama }}</div>
                    <div class="  text-center" style="font-size: 13px;">{{ $globalData.data.noreg }}</div>
                </div>
            </div>

            <img :src="$appUrl + '/img/template-kartu.jpg'" width="370px" />
        </div>
        <div class="float text-center" v-show="$globalData.data.status == '1'">
            <br />
            <br />
            maaf di data kami anda belum melakukan pembayaran
        </div>
    </div>
</template>

<script>

export default {
    name: 'Kartu',

    components: {

    },
}
</script>