import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import { func } from "@/assets/js";
import money from "v-money";
import Print from "vue-print-nb";

import "./assets/css/main.css";

import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/bootstrap-icons-1.11.3/font/bootstrap-icons.min.css";

Vue.config.productionTip = false;
Vue.prototype.$func = func;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueToast);
Vue.use(money, { precision: 0 });
Vue.use(Print);

if (process.env.NODE_ENV === "development") {
  Vue.prototype.$appUrl = "http://192.168.1.28:8800/pmb.sisbeer/";
  // Vue.prototype.$appUrl = "http://192.168.100.29:8800/pmb.sisbeer/";
  // Vue.prototype.$appUrl = "https://dinar.sayo.my.id/php.script/";
} else {
  Vue.prototype.$appUrl = "php.script/";
}

Vue.prototype.$globalData = Vue.observable({
  UserId: "",
  LoginOK: false,
  loginsukses: false,
  TokenOK: 0,
  SelectedMenu: 0,
  data: {},
  databackup: {},
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
