<template>
    <div class="float">
        <div class="row">
            <div class="col-1 gradient-background">

            </div>
            <div class="col col-md-6">

                <b-form @submit="onSubmit" @reset="onReset">
                    <b-form-input size="sm" value="Data Profile" class="mb-2 bold-input text-center" disabled />
                    <b-form-checkbox id="checkbox-1" v-model="$globalData.data.isalumni" name="checkbox-1" value="1"
                        unchecked-value="0" size="sm" class="float-right" @change="showalumni"
                        v-show="$globalData.data.jenjang == 'A'"
                        :class="{ 'font-weight-bold': $globalData.data.konfirmalumni === '1' }">
                        Saya Alumni BR
                    </b-form-checkbox>
                    Nama:
                    <b-form-input size="sm" v-model="$globalData.data.nama" class="mb-2 bold-input" />
                    Jenis Kelamin:
                    <b-form-radio-group v-model="$globalData.data.jeniskel" class="mb-2 "
                        :options="[{ text: 'Laki-laki', value: 'L' }, { text: 'Perempuan', value: 'P' }]"
                        required></b-form-radio-group>
                    <!-- NIS:
                    <b-form-input size="sm" v-model="$globalData.data.nis" class="mb-2 bold-input" /> -->
                    NIK:
                    <b-form-input size="sm" v-model="$globalData.data.nik" class="mb-2 bold-input" />
                    Tempat Lahir:
                    <b-form-input size="sm" v-model="$globalData.data.tempatlahir" class="mb-2 bold-input" required />
                    Tgl Lahir:
                    <b-form-input size="sm" v-model="$globalData.data.tgllahir" type="date" class="mb-2 bold-input"
                        required />
                    Alamat:
                    <b-form-textarea size="sm" v-model="$globalData.data.alamat" class="mb-2 bold-input" required />
                    Kota:
                    <b-form-input size="sm" v-model="$globalData.data.kota" class="mb-2 bold-input" required />
                    <!-- {{ $globalData.databackup.kota }} -->
                    Warga Negara:
                    <b-form-radio-group v-model="$globalData.data.warganegara" class="mb-2 "
                        :options="[{ text: 'WNI', value: 'wni' }, { text: 'WNA', value: 'wna' }]"></b-form-radio-group>
                    No HP:
                    <b-form-input size="sm" v-model="$globalData.data.nohp" class="mb-2 bold-input" />
                    Asal Sekolah:
                    <b-form-input size="sm" v-model="$globalData.data.asalsekolah" class="mb-2 bold-input" required />
                    Tinggi Badan (cm):
                    <b-form-input size="sm" v-model="$globalData.data.tinggi" class="mb-2 bold-input" type="number" />
                    Berat Badan (kg):
                    <b-form-input size="sm" v-model="$globalData.data.berat" class="mb-2 bold-input" type="number" />
                    Anak Ke:
                    <b-input-group size="sm" class="mb-2 bold-input">
                        <b-form-input v-model="$globalData.data.anakke" type="number" class="bold-input" />
                        <b-form-input value="Dari:" class="col-2" disabled></b-form-input>
                        <b-form-input v-model="$globalData.data.dari" type="number" class="bold-input" />
                    </b-input-group>
                    Mengetahui Informasi dari:
                    <b-form-radio-group v-model="$globalData.data.informasi" class="mb-2" stacked required
                        :options="informasi"></b-form-radio-group>
                    Masuk Baiturrahman Karena:
                    <b-form-radio-group v-model="$globalData.data.motivasi" class="mb-2" stacked required
                        :options="alasan"></b-form-radio-group>

                    <hr />
                    <b-button size="sm" class="mr-2" type="submit">Update</b-button>
                    <b-button size="sm" type="cancel">Cancel</b-button>
                </b-form>
                <br />

            </div>
        </div>
        <b-modal ref="modal-alumni" title="Alumni Baiturrahman" size="sm" hide-footer scrollable>
            <b-input-group size="sm" class="mb-2 ">
                <b-form-input type="search" v-model="search" />
                <b-input-group-append is-text>
                    <b-icon-search @click="getalumni" />
                </b-input-group-append>
            </b-input-group>
            <b-table :items="dataalumni" small style="font-size: 13px;" hover responsive
                :fields="['nis', 'nama', 'namaayah']" @row-dblclicked="pilihalumni" sticky-header="500px" />
        </b-modal>
        <b-modal ref="listalumni" title="Konfirmasi sbg Alumni" size="sm" hide-footer scrollable>
            <pre>
   Jenjang : {{ konfirmalumni.jenjang == 'P' ? 'SMP' : 'SMA' }}   
   Nama    : {{ konfirmalumni.nama }}
   Alamat  : {{ konfirmalumni.alamat }}
   TglLahir: {{ konfirmalumni.tgllahir }}
        
         </pre>
            <b-button size="sm" @click="konfirm">OK Confirm</b-button>
        </b-modal>
    </div>
</template>
<!-- {
    "identify": 59,
    "nama": "yoyo abi balqis",
    "tempatlahir": "bandung",
    "tgllahir": "2006-03-04",
    "nisn": 101,
    "nik": 101,
    "jeniskel": "L",
    "alamat": "Mutiara Depok B12",
    "nohp": 8151633007,
    "asalsekolah": "SD Binakhair",
    "pilihanjurusan": "",
    "warganegara": "wni",
    "tinggi": 165,
    "berat": 56,
    "anakke": 2,
    "dari": 4,

    "jenisayah": "",
    "namaayah": "",
    "tmplahirayah": "",
    "tgllahirayah": "",
    "pekerjaanayah": "",
    "penghasilanayah": "",
    "pendidikanayah": "",
    "alamatayah": "",

    "jenisibu": "",
    "namaibu": "",
    "tmplahiribu": "",
    "tgllahiribu": "",
    "pekerjaanibu": "",
    "penghasilanibu": "",
    "pendidikanibu": "",

    "informasi": "c",
    "motivasi": "d",
    "userid": "yoyo",
    "password": 123,
    "status": 1,
    "jenjang": "P",
    "kota": "Depok",
    "kotaayah": null,
    "nova": 7216240225261003,
    "pasfoto": "uploads/pasfoto-yoyo.jpg",
    "kk": null,
    "akte": null,
    "ktpa": null,
    "ktpi": null,
    "ketsehat": null,
    "isalumni": 0,
    "gelombang": 1
  } -->

<script>
import axios from "axios";
import _ from "lodash";

export default {
    name: 'Profile',

    components: {

    },
    data() {
        return {
            search: '',
            dataalumni: [],
            konfirmalumni: {},
            informasi: [{ text: 'a. Website Baiturrahman', value: 'a' },
            { text: 'b. Media Sosial', value: 'b' },
            { text: 'c. Alumni Baiturrahman', value: 'c' },
            { text: 'd. Saudara/Teman', value: 'd' },
            { text: 'e. Media Massa (Radio, Koran, dll)', value: 'e' },
            { text: 'f. Spanduk/Brosur', value: 'f' },
            { text: 'g. Road Show/ Presentasi Panitia', value: 'g' },
            { text: 'h. Lain-lain', value: 'h' }
            ],
            alasan: [{ text: 'a. Kemauan Sendiri', value: 'a' },
            { text: 'b. Kemauan Orangtua', value: 'b' },
            { text: 'c. Kemauan Sendiri dan Orangtua', value: 'c' },
            { text: 'd. Mengikuti Teman', value: 'd' },
            { text: 'e. Coba-coba', value: 'e' },
            ],
        }
    },
    mounted() {
        // if (!this.$globalData.data.warganegara) {
        //     this.$globalData.data.warganegara = 'wni';
        // }
    },
    watch: {
        search: _.debounce(function (newQuery) {
            this.searchUsers(newQuery);
        }, 500),
    },
    methods: {
        pilihalumni(dt) {
            this.konfirmalumni = dt
            this.$refs['listalumni'].show()

        },

        konfirm() {
            let dt = this.konfirmalumni
            console.log('alumni', dt)

            // this.$globalData.data.nis = dt.nis
            this.$globalData.data.nik = dt.nik
            this.$globalData.data.nama = dt.nama
            this.$globalData.data.alamat = dt.alamat
            this.$globalData.data.kota = dt.kota
            this.$globalData.data.namaayah = dt.namaayah
            this.$globalData.data.tempatlahir = dt.tempatlahir
            this.$globalData.data.tgllahir = dt.tgllahir
            this.$globalData.data.jeniskel = dt.jeniskel
            this.$globalData.data.namaayah = dt.namaayah
            this.$globalData.data.namaibu = dt.namaibu
            this.$globalData.data.alamatayah = dt.alamatayah
            this.$globalData.data.kotaayah = dt.kotaayah
            this.$refs['modal-alumni'].hide()
            this.$refs['listalumni'].hide()

        },
        searchUsers(newQuery) {
            axios
                .get(
                    this.$appUrl +
                    `getalumni.php?q=${newQuery}`

                )
                .then((res) => {
                    this.dataalumni = res.data;
                });
        },

        getalumni() {
            this.dataalumni = [{ nama: 'Ali', nis: '23423424' }, { nama: 'Rini', nis: '48029842204' }]

        },
        showalumni() {
            if (this.$globalData.data.isalumni == 1) {
                this.$refs['modal-alumni'].show()
            }
        },
        onReset() {
            this.$globalData.data = JSON.parse(JSON.stringify(this.$globalData.databackup))
        },
        onSubmit(event) {
            event.preventDefault();
            axios
                .post(this.$appUrl + "update.php?jenis=profile" + "&token=" + localStorage.getItem('psbtoken'),
                    this.$globalData.data)
                .then((res) => {
                    console.log(res)
                    this.$toast.success("Sukses Update", {
                        type: "info",
                        position: "top-right",
                        duration: 3000,
                        dismissible: true,
                    });
                })


        },
        saveprofile() {
            console.log('data', this.$globalData.data);
            alert(JSON.stringify(this.$globalData.data))
        },
        cancelprofile() {
            // const x = this.$globalData.databackup
            this.$globalData.data = JSON.parse(JSON.stringify(this.$globalData.databackup))
            console.log("bakcup", this.$globalData.databackup)
        }
    }
}
</script>
<style>
.gradient-background {
    height: auto;
    /* Atur tinggi sesuai kebutuhan */
    background: linear-gradient(to left, white, green);
    /* background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 121, 24, 0.8464811950170693) 12%, rgba(0, 212, 255, 1) 100%); */
    /* Ganti dengan warna gradasi yang Anda inginkan */
}

.bold-input {
    font-weight: bold !important;
}
</style>