<template>
   <div class="fluid">
      <footer class="mt-3 mb-1 text-blue">
         <!-- <hr /> -->

         <div class="row text-white gradient rounded ml-1 mr-1">
            <div class="col text-center mb-0" style="font-size: 8px">
               Copyright Ponpes Baiturrahman 2024
            </div>
         </div>
         <br />
         <br />
         <br />
         <br />
      </footer>
   </div>
</template>

<script>
export default {
   name: "Footer",
};
</script>

<style>
.gradient {
   background: linear-gradient(to bottom, #134a6e 33%, #66ff99 100%);
}
</style>
